import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#FAE70D",
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        backgroundColor: "white",
        fontSize: "14px",
      },
    },
  },
});

const BirthdayPicker = ({ value, onChange, disabled }) => {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <DatePicker
          disableFuture
          openTo="year"
          format="dd/MM/yyyy"
          value={value}
          views={["year", "month", "date"]}
          onChange={onChange}
          disabled={disabled}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default BirthdayPicker;
